/* Resume */

.resume-card {
    position: relative;
    z-index: 1;
    /* margin-bottom: 2rem; */
    padding: 0;
    background: linear-gradient(to bottom, rgb(5, 5, 41), rgb(61, 67, 102));
    font-size: 20px;
    /* Add styles for the box */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 65%;
    margin: 4rem auto;
    margin-bottom: 4rem;
    color: white;
    /* max-height: 40rem; */
  }
  
  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 115%;
    height: 100%;
    /* margin-bottom: 2rem; */
    object-fit: cover;
    z-index: -1; /* Set a negative z-index to place the video behind the card */
  }
  
  .resume-card h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 8px;
    text-align: center;
  }
  
  .resume-card p {
    font-size: 1rem;
    margin: 1rem;
    color: whitesmoke;
  }
  
  .resume-card ul {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    padding: 0;
    text-align: center;
  }
  
  .resume-card h4 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  /* .resume-card li {
    font-size: 24px;
    margin-bottom: 2px;
  } */
  
 
  
  .resume-title:hover {
    color: rgb(231, 235, 12);
  }

  .resume-download-button {
    margin-bottom: 1rem;
    margin-left: 1rem;
    padding: 1rem;
    }

    /* .resume-download-button button {
        
        background-color: #007bff; 
        color: #fff; 
        padding: 0.5rem 1rem; 
        border: none; 
        cursor: pointer; 
        font-size: 1rem; 
        font-weight: bold; 
        border-radius: 4px; 
        transition: background-color 0.3s ease-in-out; 
        width: 13rem;
      } */
      
      .resume-download-button button:hover {
        /* Change styles on hover */
        background-color: #0056b3; /* Change background color */
      }  