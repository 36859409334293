body {
  position: relative;
  font-family: "Orbitron", "Quantico", sans-serif;
  /* font-size: 2rem; */
  background: linear-gradient(to bottom, #000000, #333333);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  color: white;
}

/* Media Query for Mobile Devices */
@media only screen and (max-width: 768px) {
  body {
    font-size: 1.6rem;
    font-family: "Orbitron", "Quantico", sans-serif;
    color: white;
    box-sizing: border-box;
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .navbar .nav-tabs {
    display: none;
  }

  .nav-link {
    color: white;
    text-decoration: none;
    margin: 10px 0;
  }

  .navbar .nav-tabs.active {
    display: flex; /* Show the menu items when active */
    flex-direction: column; /* Adjust the layout for mobile */
    align-items: center;
    background-color: #333;
    padding: 20px;
  }

  .navbar .nav-tabs.active li {
    margin: 10px 0; /* Adjust the spacing between menu items */
  }

  .navbar .nav-tabs.active li a {
    color: white;
    text-decoration: none;
  }
}

/* Media Query for Tablets and Large Mobile Devices */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  body {
    font-size: 1rem;
  }
}

/* Media Query for Laptops and Desktops */
@media only screen and (min-width: 1025px) {
  body {
    font-size: 1rem;
  }
}

/*Home*/
.home-container {
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
 
}

.home-container .background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 55vh; 
  object-fit: cover;
  background-size: contain;
}

.home-content h1 {
  font-size: 2rem;
  font-weight: bold;
  color: rgb(68, 131, 190);
  margin-top: 11.5rem; 
  max-width: 28rem; 
  text-align: center; 
  margin-left: 1rem;
}

.home-content p {
  font-size: 1.3rem;
  line-height: 2rem;
  /* text-align: justify; */
  color: white;
  padding-top: 3.5rem;
  max-width: 24rem; 
  text-align: center; 
  margin-right: 2rem;
  margin-left: 3rem;
}

/*About me*/

.aboutme-container {
  background-image: url('./images/Background.jpg');
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  padding-bottom: 18rem; /* adjust the value to your desired amount */
}


.description {
  font-size: 1rem;
  color: black;
  max-width: 40%;
  margin-left: 5rem;
}

.aboutme {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: black;
  margin-left: 7rem;
}

.profile-picture {
  max-width: 15%;
  height: auto;
  border-radius: 2%;
  margin-left: 10rem;
  margin-top: 3rem;
  /* Add any other styling for the profile picture here */
}


/* Navbar */

.navbar {
  display: flex;
  align-items: center;
  
}

.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.line {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 3px;
}

.nav-tabs {
  display: flex;
  flex-direction: column; 
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.projects,
.contact,
.about-me,
.resume {
  color: whitesmoke;
  padding-right: 0;
  margin: 0.5rem 0.3rem; /* Adjust the margin for better spacing */
  font-family: "Orbitron", "Quantico", sans-serif;
  font-size: 1.2rem;
  line-height: 1.9;
  list-style-type: none;
  text-align: center; /* Center the text for mobile */
}

.projects:hover {
  color: rgb(245, 71, 40);
}

.contact:hover {
  color: magenta;
}

.about-me:hover {
  color: cyan;
}

.resume:hover {
  color: rgb(231, 235, 12);
}


/* Hamburger Menu Styling */
/* .navbar-toggler {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.navbar-toggler-icon {
  background-color: #fff; 
}

.navbar-collapse {
  background-color: #333; 
}

.nav-link {
  color: #fff !important; 
}

.nav-link:hover {
  color: #f00 !important; 
} */

/*Projects Section*/

.projects-container {
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}

.projects-background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 120%; 
  height: 215vh; 
  object-fit: cover;
  background-size: contain;
}

.projects-section span {
  font-size: 28px;
  position: relative;
  color: aliceblue;
}

.title h2{
  font-size: 1.5rem;
  text-align: center;
  margin-left: 5rem;
  margin-top: 5rem;
  /* margin-bottom: -3rem; */
  color: white;
}

.title:hover h2{
  color: rgb(245, 71, 40);
}

.project-card {
  position: relative; 
  width: 100%;
  height: 19rem; 
  margin-bottom: 15rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem; 
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  top: 7rem;
  align-items: center; 
  /* animation: slide-in 20s infinite; */
  color: aliceblue;
}

@keyframes slide-in {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(50%);
  }
}

.project-card img {
  display: block;
  width: 100%; 
  height: 100%;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.project-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease-in-out; 
}

.project-card:hover .project-card-overlay {
  opacity: 1; 
}

.project-card-links  {
  color: white
}

.project-card-details {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; 
  width: 121%;
  /* margin: 4rem auto; */
  margin-bottom: 4rem;
  height: 45rem;
  padding: 20px 10px 20px 10px;
  font-size: 15px;
  color: aliceblue;
} 

.project-card-details p {
  color: whitesmoke;
  border: none;
  border-radius: 5px;
  background: linear-gradient(to bottom, rgb(5, 5, 41), rgb(61, 67, 102));
  width: 55rem;
  height: 33rem;
  margin-right: 7rem;
  margin-left: auto;
  /* margin-bottom: 2rem; */
  padding: 20px 10px 20px 10px;
  font-size: 1rem;
}

.project-card-details h4 {
  font-size: 1.5rem;
  text-align: center;
  color: whitesmoke;
  width: 10rem;
  margin-right: 8rem;
  margin-bottom: 1rem;
  position: relative;
  border-radius: 5px;
  border: none;
  background: linear-gradient(to bottom, rgb(5, 5, 41), rgb(61, 67, 102));
  padding: 0 1.2rem;
}

.project-card-overlay h3 {
  color: #fff; /* set desired link text color */
  font-size: 1rem; /* set desired link text size */
  text-align: center;
  
}

.project-card-overlay a {
  color: #fff; /* set desired link color */
  text-decoration: none; /* remove default underline style */
}

.project-card-overlay a:hover {
  text-decoration: underline; /* add underline effect on hover */
}

.project-card-icons {
  position: relative;
  margin-top: -20rem;
  margin-right: 13rem;
  /* margin-bottom: 8rem; */
  padding: 0 1.5rem;
  font-size: 2rem;
}

.project-card-button button {
  /* margin-top: -18rem; */
  margin-bottom: 1rem;
  margin-right: 15rem;
  color: whitesmoke;
  padding: 0.5rem 0.5rem;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}



.project-card-details h3{
  position: relative;
  /* margin-top: -10rem; */
  margin-right: 8rem;
  padding: 0 1.4rem;
  border: none;
  border-radius: 5px;
  background-color: green;
  font-size: 1.5rem;
  color: whitesmoke;
  width: 10rem;
}

.ez {
  color:blue;
}

.carousel-container {
  position: relative;
  display: block;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  /* align-items: flex-start; */
  margin: 0 auto;
  width: 25rem;
  /* object-fit: cover;
  transition: transform 0.3s ease-in-out; */
}

/* Resume */

.resume-container {
  margin-bottom: 0;
}

.resume-card {
  position: relative;
  z-index: 1;
  /* margin-bottom: 0rem; */
  padding: 20px 10px 20px 10px;
  /* background: rgb(5, 5, 41); */
  background: linear-gradient(to bottom, rgb(5, 5, 41), rgb(61, 67, 102));
  font-size: 20px;
  /* Add styles for the box */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 60%;
  margin: 4rem auto;
  color: white;
}

.resume-container .background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -1; /* Set a negative z-index to place the video behind the card */
}

.resume-card h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;
}

.resume-card p {
  font-size: 1rem;
  margin: 1rem;
  color: whitesmoke;
}

.resume-card ul {
  font-size: 2rem;
  margin-bottom: 1rem;
  padding: 0;
  text-align: center;
}

.resume-card h4 {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

/* .resume-card li {
  font-size: 24px;
  margin-bottom: 2px;
} */



.resume-title:hover {
  color: rgb(231, 235, 12);
}

.resume-download-button {
  margin-bottom: 1rem;
  margin-left: 17rem;
  }

  .resume-download-button button {
      /* Customize button styles */
      background-color: #007bff; /* Set background color */
      color: #fff; /* Set text color */
      padding: 0.5rem 1rem; /* Set padding */
      border: none; /* Remove border */
      cursor: pointer; /* Add cursor pointer */
      font-size: 1rem; /* Set font size */
      font-weight: bold; /* Set font weight */
      border-radius: 4px; /* Add border radius */
      transition: background-color 0.3s ease-in-out; /* Add transition effect */
    }
    
    .resume-download-button button:hover {
      /* Change styles on hover */
      background-color: #0056b3; /* Change background color */
    }  

/* Footer.css */
.footer-container {
  height: 60px; 
  width: 100%;
  line-height: 60px;
  background: black;
  position: relative;
  /* margin-top: rem; */
  /* margin-bottom: 0; */
  /* border-top: 2px solid gray; */
  /* border-bottom: 5px solid #6b6b6b; */
  color: #FDF8F5;
  text-align: center;
  padding-left: 1rem; /* Add padding on the left */
  padding-right: 1rem; /* Add padding on the right */
  font-size: 2rem;
}

.footer-container .copyright {
  font-size: 1.3rem;
  margin-right: 3rem;
  /* background: linear-gradient(to bottom, #000000, #333333); */
}

.footer-container .social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 10px;  */
  /* background: black; */
  margin-right: 3rem;
}

.icon {
  margin-right: 1rem; /* Adjust margin between the icons as needed */
  font-size: 2rem;
  color: whitesmoke;
}

.icon-spacing {
  width: 1rem; /* Add spacing between the icons as needed */
}

/* .contact-info {
  margin-top: 1rem;
} */

/*Header*/

.header-container {
  position: relative;
  text-align: center;
  z-index: 100;
  width: 100%;
  height: auto;
  line-height: 50px;
  /* margin-top: 0.1rem; */
  /* padding: 1rem; */
  color:whitesmoke;
  background-color: none;
  text-decoration: none;
  font-family: "Orbitron","Quantico", sans-serif;
  }

.header-container .about-me {
  text-decoration: none;
}

.header-container .projects {
  text-decoration: none;
}

.header-container .contact {
  text-decoration: none;
}

.header-container .resume {
  text-decoration: none;
}

.header-container .logo-relative:hover {
      color: limegreen;
      }
  
.header-container .logo-relative {
        position: relative;
        z-index: 100;
        color: whitesmoke;
        font-size: 1.6rem;
        text-decoration: none;
        justify-content: center;
        margin-right: 2rem;
        width: 23rem;
        font-weight: bold;
      }
      
.header-container .logo-relative::before,
      .logo-relative::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }
      
.header-container .logo-relative::before {
        /* background-color: #0BC5EA; Replace with desired color */
        animation: glitch-1 2s infinite;
      }
      
.header-container .logo-relative::after {
        
        animation: glitch-2 3s infinite;
      }
    
     @keyframes glitch-anim-1 {
      0% {
        transform: translate(0);
        opacity: 1;
        clip: rect(0, 900px, 100px, 0);
      }
      2% {
        transform: translate(-5px, 5px);
        clip: rect(40px, 900px, 30px, 0);
      }
      4% {
        transform: translate(-5px, -10px);
        clip: rect(80px, 900px, 60px, 0);
      }
      6% {
        transform: translate(5px, 5px);
        clip: rect(20px, 900px, 50px, 0);
      }
      8% {
        transform: translate(5px, -5px);
        clip: rect(90px, 900px, 30px, 0);
      }
      10% {
        transform: translate(5px, 10px);
        clip: rect(40px, 900px, 80px, 0);
      }
      12% {
        transform: translate(-10px, -10px);
        clip: rect(50px, 900px, 60px, 0);
      }
      14% {
        transform: translate(10px, 10px);
        clip: rect(10px, 900px, 30px, 0);
      }
      16% {
        transform: translate(5px, -5px);
        clip: rect(80px, 900px, 50px, 0);
      }
      18% {
        transform: translate(-5px, 5px);
        clip: rect(30px, 900px, 70px, 0);
      }
      20% {
        transform: translate(-5px, -5px);
        clip: rect(90px, 900px, 20px, 0);
      }
      22% {
        transform: translate(-10px, 10px);
        clip: rect(80px, 900px, 40px, 0);
      }
      24% {
        transform: translate(10px, -10px);
        clip: rect(50px, 900px, 90px, 0);
      }
      26% {
        transform: translate(10px, 5px);
        clip: rect(30px, 900px, 100px, 0);
      }
      28% {
      transform: translate(-5px, 5px);
      clip: rect(80px, 900px, 50px, 0);
      }
      30% {
      transform: translate(-5px, -5px);
      clip: rect(20px, 900px, 70px, 0);
      }
      32% {
          clip: rect(10px, 900px, 10px, 0);
      }
      34% {
        transform: translate(5px, -10px);
        clip: rect(90px, 900px, 80px, 0);
      }
      36% {
        transform: translate(-5px, 10px);
        clip: rect(10px, 900px, 30px, 0);
      }
      38% {
        transform: translate(-10px, -10px);
        clip: rect(40px, 900px, 70px, 0);
      }
      40% {
        transform: translate(10px, 5px);
        clip: rect(70px, 900px, 80px, 0);
      }
      42% {
        transform: translate(-5px, -5px);
        clip: rect(50px, 900px, 50px, 0);
      }
      44% {
        transform: translate(5px, -10px);
        clip: rect(40px, 900px, 70px, 0);
      }
      46% {
        transform: translate(-10px, 10px);
        clip: rect(20px, 900px, 10px, 0);
      }
      48% {
        transform: translate(10px, -10px);
        clip: rect(80px, 900px, 60px, 0);
      }
      50% {
        transform: translate(-5px, 5px);
        clip: rect(60px, 900px, 90px, 0);
      }
      52% {
        transform: translate(10px, 5px);
        clip: rect(40px, 900px, 60px, 0);
      }
      54% {
        transform: translate(5px, -5px);
        clip: rect(10px, 900px, 80px, 0);
      }
      56% {
        transform: translate(-5px, 10px);
        clip: rect(60px, 900px, 10px, 0);
      }
      58% {
        transform: translate(-10px, -5px);
        clip: rect(30px, 900px, 50px, 0);
      }
      60% {
        transform: translate(5px, -5px);
        clip: rect(70px, 900px, 60px, 0);
      }
      62% {
        transform: translate(10px, 5px);
        clip: rect(90px, 900px, 80px, 0);
      }
      64% {
        transform: translate(5px, -10px);
        clip: rect(30px, 900px, 30px, 0);
      }
      66% {
        transform: translate(-10px, 10px);
        clip: rect(10px, 900px, 50px, 0);
}
68% {
  transform: translate(5px, -5px);
  clip: rect(20px, 900px, 30px, 0);
}
70% {
  transform: translate(-5px, 10px);
  clip: rect(90px, 900px, 20px, 0);
}
72% {
  transform: translate(10px, -10px);
  clip: rect(80px, 900px, 60px, 0);
}
74% {
  transform: translate(10px, 5px);
  clip: rect(60px, 900px, 10px, 0);
}
76% {
  transform: translate(5px, -10px);
  clip: rect(10px, 900px, 80px, 0);
}
78% {
  transform: translate(5px, 10px);
  clip: rect(50px, 900px, 10px, 0);
}
80% {
  transform: translate(-5px, -5px);
  clip: rect(20px, 900px, 70px, 0);
}
82% {
  transform: translate(-10px, 10px);
  clip: rect(10px, 900px, 50px, 0);
}
84% {
  transform: translate(5px, -5px);
  clip: rect(40px, 900px, 30px, 0);
}
86% {
  transform: translate(-5px, 10px);
  clip: rect(80px, 900px, 20px, 0);
}
88% {
  transform: translate(10px, -10px);
  clip: rect(30px, 900px, 60px, 0);
}
90% {
  transform: translate(10px, 5px);
  clip: rect(70px, 900px, 10px, 0);
}
92% {
  transform: translate(5px, -10px);
  clip: rect(10px, 900px, 80px, 0);
}
94% {
  transform: translate(5px, 10px);
  clip: rect(60px, 900px, 10px, 0);
}
96% {
  transform: translate(-5px, -5px);
  clip: rect(20px, 900px, 70px, 0);
}
98% {
  transform: translate(-10px, 10px);
  clip: rect(10px, 900px, 50px, 0);
}
100% {
  transform: translate(0);
  opacity: 1;
  clip: rect(0, 900px, 100px, 0);
}
}

 
