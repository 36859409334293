/*Contact*/

.contact-container {
  position: relative;
  z-index: 1;
  /* margin-bottom: 0rem; */
  padding: rem;
  background: linear-gradient(to bottom, rgb(5, 5, 41), rgb(61, 67, 102));
  font-size: 20px;
  /* Add styles for the box */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 75%;
  margin: 2rem auto;
  margin-bottom: 0rem;
  color: white;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -1; /* Set a negative z-index to place the video behind the card */
}

/* .contact-container input[type="text"] {
  width: 50%;
  font-size: 18px;
  padding: 15px;
  margin-left: 4rem;
  margin-bottom: 1rem;
} */

/* .contact-container label {
  display: block;
  margin-bottom: 4px;
  margin-left: 4rem;
} */

/* .contact-container button[type="submit"] {
  font-size: 18px;
  padding: 10px 20px;
  
  background-color: lightblue;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 4rem;
  margin-top: 1rem;
} */

/* .contact-container button:hover {
  color: magenta;
} */

.contact-title {
  font-size: 1.5rem;
  margin-left: 2rem;
  text-align: center;
}

.contact-title:hover {
  color: magenta;
}

.contact-header {
  font-size: 1.5rem;
  margin-left: 4rem;
}

.design-inquiries p {
  font-size: 1.2rem;
  margin-left: 2.7rem;
  width: 73%;
}

.design-inquiries ul {
  margin-top: 5rem;
  /* margin-right: 2rem; */
}

.design-inquiries li {
  font-size: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
}

.design-inquiries li button {
  font-size: 1rem;
  margin-top: 1rem;
  margin-left: 0.5rem;
  background-color: #007bff;
  color:aliceblue;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
}

/* .text-right-message {
  margin-bottom: 3rem;
} */