/*About me*/

.aboutme-container  {
  background-image: url('../images/Background.jpg');
  width: 100%;
  height: 100%;
  display: block;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  padding-bottom: 10rem; 
  /* margin: 2rem; */
}

.aboutme-container .description {
  position: relative;
  font-size: 1rem;
  color: black;
  max-width: 55%;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.aboutme-container .aboutme {
  position: relative;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: black;
  margin-left: 3rem;
  width: 15rem;
}

.profile-picture {
  position: relative;
  max-width: 28%;
  height: auto;
  border-radius: 5%;
  margin-left: 5rem;
  margin-top: 3rem;
  /* Add any other styling for the profile picture here */
}

/* .aboutme-container h2 {
  position: relative;
    font-size: 2rem;
    font-weight: bold;
    margin-right: 20rem;
    color: black;
    
} */

.abouttitle {
  font-size: 2.5rem;
  margin-left: 5rem;
  color:black;
}

.abouttitle:hover {
  color: cyan;
}